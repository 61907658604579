import axios from "axios";
import qs from "qs";
import secureLocalStorage from "react-secure-storage";

var openam = {
  authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
  user_profile_url: process.env.REACT_APP_USER_PROFILE_URL,
  app_url: process.env.REACT_APP_APP_URL,
  //app_url: 'http://localhost:3000/',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  logoutUrl: process.env.REACT_APP_LOGOUTURL,
  token_info: process.env.REACT_APP_TOKEN_INFO,
};

export const CheckAuthentication = async () => {
  const urlParams = new URLSearchParams(window.location.search);

  let token;
  if (urlParams.has("code")) {
    const myParam = urlParams.get("code");

    const { data } = await axios.post(
      openam.token_endpoint,
      qs.stringify({
        code: myParam,
        client_id: openam.client_id,
        client_secret: openam.client_secret,
        redirect_uri: openam.app_url,
        grant_type: "authorization_code",
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    let returnVal = await convertSuccessfulLoginToJson(data);
    if (returnVal) return true;
  }
  token = secureLocalStorage.getItem("OPENAMSESSION");
  if (token === undefined || token === null || token === "") {
    performOpenamLogin();
    return false;
  }
  return true;
};

export const performOpenamLogin = () => {
  var openAmURL = `${
    openam.authorization_endpoint
  }?response_type=code&client_id=${
    openam.client_id
  }&redirect_uri=${encodeURIComponent(openam.app_url)}&scope=openid profile`;

  window.location.href = openAmURL;
};
export const convertSuccessfulLoginToJson = async (data) => {
  // Once authenticated add token to session storage and open application home page

  secureLocalStorage.setItem("OPENAMSESSION", JSON.stringify(data));

  return true;
};
