import { React, useState, useEffect } from "react";
import {
  Loading,
  Input,
  Upload,
  CalendarPicker,
  Button,
  Notification,
  toaster,
  Panel,
  TextArea,
} from "@appkit4/react-components";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import { postrequest } from "../../services/global.service";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultErrors = {
  surveyNameError: false,
  surveyDescriptionError: false,
  fileError: false,
};

const UploadSurvey = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [isEdit, setIsedit] = useState(false);

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [surveydetails, setSurveydetails] = useState({
    expiryDate: undefined,
    surveyDescription: "",
    surveyName: "",
    surveyId: "",
  });
  const [errors, setErrors] = useState(defaultErrors);

  useEffect(() => {
    setIsLoading(true);
    if (location.state != null) {
      setIsedit(true);
      let surveyDetailsLocation = {
        surveyName: location.state.surveyName,
        surveyDescription: location.state.surveyDescription,
        surveyId: location.state.surveyid,
        expiryDate: location.state.expiryDate
          ? new Date(location.state.expiryDate)
          : undefined,
      };
      setSurveydetails(surveyDetailsLocation);
    }
    setIsLoading(false);
  }, []);

  let userinfo = JSON.parse(Cookies.get("userdetails"));

  const fileHandler = (file) => {
    setSelectedFile(file);
  };

  const cancelClick = () => {
    navigate(-1);
  }

  const validate = () => {
    setErrors((prevState) => ({
      ...prevState,
      fileError: !selectedFile ? true : false,
      surveyNameError: surveydetails.surveyName === "" ? true : false,
      surveyDescriptionError:
        surveydetails.surveyDescription === "" ? true : false,
    }));
    if (
      !selectedFile ||
      surveydetails.surveyName === "" ||
      surveydetails.surveyDescription === ""
    ) {
      return true;
    }
    return false;
  };

  const submitSurvey = (event) => {
    event.preventDefault();
    if (validate()) {
      return false;
    } else {
      const formData = new FormData();
      formData.append("File", selectedFile.originFile);
      formData.append("surveyName", surveydetails.surveyName);
      formData.append("surveyDescription", surveydetails.surveyDescription);
      formData.append("createdBy", userinfo.email);
      formData.append("teamName", JSON.parse(Cookies.get("seletedTeam")).name);

      if (isEdit) {
        formData.append("versionFlag", 1);
        formData.append("expiryDate", surveydetails.expiryDate);
      } else {
        formData.append("versionFlag", 0);
        formData.append(
          "expiryDate",
          surveydetails.expiryDate
            ? moment(surveydetails.expiryDate).format("DD-MM-YYYY")
            : ""
        );
      }

      var URL = `${baseURL}/api/Survey/AddSurvey?teamnName=${
        JSON.parse(Cookies.get("seletedTeam")).name
      }`;

      postrequest(URL, formData)
        .then((response) => {
          displayAlert(response.data.message);
          cancelClick();
        })
        .catch((error) => {
          navigate("/service-problem");
        });
    }
  };

  const handleInputChange = (value, e) => {
    const { name } = e.target;
    setSurveydetails({
      ...surveydetails,
      [name]: value,
    });
  };

  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 20000 }
    );
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Button onClick={cancelClick} kind="text" className="back-button">
        Back
      </Button>

      <Panel title="Enter Questionnaire Details">
        <Input
          required
          id="surveyName"
          title="Questionnaire Name"
          name="surveyName"
          value={surveydetails.surveyName}
          onChange={handleInputChange}
          className="mb-md-3"
          error={errors.surveyNameError}
          errorNode={
            errors.surveyNameError && (
              <div
                id="errormessage"
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a survey name
              </div>
            )
          }
        />

        <TextArea
          required
          id="surveyDescription"
          title="Questionnaire Description"
          name="surveyDescription"
          value={surveydetails.surveyDescription}
          onChange={handleInputChange}
          className="mb-md-3"
        />

        {errors.surveyDescriptionError && (
          <div
            id="errormessage"
            aria-live="polite"
            className="ap-field-email-validation-error"
          >
            Please enter a survey description
          </div>
        )}

        <Upload
          onChange={fileHandler}
          multiple={false}
          autoUpload={false}
          acceptFileType=".XLSX"
          uploadInstruction="You can upload Excel files. The max file size is 10mb."
          uploadTitle="Upload your file"
          maxFileSize={10 * 1024 * 1024}
          config={{
            trigger: false,
            type: "inline",
            size: true,
          }}
        />
        {errors.fileError && (
          <div className="ap-field-email-validation-error">
            Please upload a file
          </div>
        )}

        <CalendarPicker
          placeholder="DD/MM/YYYY"
          locale="en"
          fieldTitle="Expiry date"
          minDate={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() + 1
            )
          }
          onChange={(date) =>
            setSurveydetails({
              ...surveydetails,
              expiryDate: date,
            })
          }
          format="DD/MM/YYYY"
          value={surveydetails.expiryDate}
          disabled={isEdit}
          className="mb-md-3"
        />

        <Button kind="primary" type="submit" onClick={submitSurvey}>
          Save
        </Button>
      </Panel>
    </div>
  );
}

export default UploadSurvey;
