import Cookie from "js-cookie";

const UseAuth = () => {
  var userdata = Cookie.get("seletedTeam")
    ? Cookie.get("seletedTeam")
    : Cookie.get("userdetails");
  if (userdata) {
    userdata = JSON.parse(userdata);
    return userdata;
  } else {
    return {};
  }
}
export default UseAuth;
