import { BlobServiceClient } from "@azure/storage-blob";
import config from "./config";

const connStr = process.env.REACT_APP_CONNECTION_STRING;
const blobServiceClient = new BlobServiceClient(process.env.REACT_APP_CONNECTION_STRING);
const containerClient = blobServiceClient.getContainerClient("upload");

export const uploadBlob = async (file) => {
  try {
    // Generate timestamp
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0]; // YYYY-MM-DD format
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS format

    const blobName = `${file.name.split(".")[0]}_${formattedDate}_${time}.${file.name.split(".").pop()}`;

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    const uploadBlobResponse = await blockBlobClient.uploadData(file);

    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading blob:", error);
    throw error;
  }
};


  export const downloadBlob = async (fileName) => {
    try {
      const blobClient = containerClient.getBlobClient(fileName);
      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody;
      return blob;
    } catch (error) {
      console.error("Error downloading blob:", error);
      throw error;
    }
  };
  
export { containerClient };
