import React from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const Terms = () => {
  const location = useLocation();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  return (
    <div className="ap-container">
      <h2>Terms of use</h2>

      <p>Last updated: May 2023</p>
      <p>
        You have been given access by PricewaterhouseCoopers LLP (also referred
        to as "us", or "we") to Questionnaire Cloud (the "tool"). The purpose of
        the tool is to allow PricewaterhouseCoopers users to create their own
        questionnaires and forms to send via email to other internal PwC users
        and external clients.
      </p>
      <p>
        These terms form an agreement between you and us; please read them
        carefully.
      </p>
      <p>
        This agreement will take effect when you click the "I have read and
        accepted..." button. By clicking "I have read and accepted...", you
        agree to be bound by these terms of use.
      </p>
      <p>
        We may amend these terms from time to time. The amended terms will
        either be notified to you, or be available for you to view on (or when
        accessing) the tool.
      </p>
      <ol>
        <li>
          <p>Use</p>
          <ol>
            <li>
              <p>
                Permitted use. You may only use the tool, the content on it, and
                the outputs from it, for their intended purpose and subject to
                these terms save as otherwise agreed in writing.
              </p>
            </li>
            <li>
              <p>Restrictions on use. You may not:</p>
              <ol>
                <li>
                  <p>
                    act or omit to act in any way that puts you or any entity
                    you work for in breach of any agreement with any PwC firm,
                    or any applicable law or regulation;
                  </p>
                </li>
                <li>
                  <p>
                    make the tool, the contents or the outputs available to any
                    person, other than as permitted by us;
                  </p>
                </li>
                <li>
                  <p>
                    copy, modify, disassemble, work around, by-pass, reverse
                    engineer or otherwise change the tool or its underlying
                    data, systems or software, or attempt to do so;
                  </p>
                </li>
                <li>
                  <p>
                    copy or use the contents or outputs, other than as expressly
                    permitted by us;
                  </p>
                </li>
                <li>
                  <p>
                    engage in or encourage unlawful, invasive, infringing,
                    defamatory or fraudulent activity (including to violate, or
                    encourage the violation of, the legal rights of others);
                    impersonate another person or otherwise misrepresent your
                    identity or other information about you;
                  </p>
                </li>
                <li>
                  <p>
                    remove or alter any identification, proprietary, copyright
                    or other notices on the tool or the contents or outputs;
                  </p>
                </li>
                <li>
                  <p>
                    access or use the tool on any publicly available equipment;
                    or
                  </p>
                </li>
                <li>
                  <p>
                    bypass or attempt to bypass any of the access or security
                    controls or otherwise weaken or threaten the security of the
                    tool.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Incorrect access. You agree to notify us immediately, and then
                exit the tool, if you access content or outputs on it that are
                not intended for you.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="2">
        <li>
          <p>Limitation of liability</p>
          <ol>
            <li>
              <p>&nbsp; You and we agree that:</p>
              <ol>
                <li>
                  <p>
                    the tool, the content and outputs are provided on an 'as-is'
                    basis;
                  </p>
                </li>
                <li>
                  <p>
                    to the extent permitted by law, no warranties or other
                    provisions are implied into these terms. We do not warrant
                    that the operation of the tool will be uninterrupted;
                  </p>
                </li>
                <li>
                  <p>
                    to the extent permitted by law, we do not accept any
                    liability, responsibility or duty of care to you for the
                    tool, the content or outputs, or for any consequences of you
                    or anyone acting or refraining to act in reliance on them,
                    or for any decision based on them, other than in relation to
                    any errors relating solely to the underlying logic of the
                    tool itself. In particular, we do not accept any liability,
                    responsibility or duty of care to you in relation to errors
                    created due to incorrect inputs to the tool made by you;
                  </p>
                </li>
                <li>
                  <p>
                    to the extent permitted by law, and in accordance with the
                    terms set out above, in any circumstances where we do accept
                    liability, responsibility or duty of care to you, our
                    overall liability is limited to the annual licence fee for
                    the tool paid to us by you;
                  </p>
                </li>
                <li>
                  <p>
                    in providing you with the tool we are not providing you with
                    any form of accountancy advice; and
                  </p>
                </li>
                <li>
                  <p>
                    nothing in these terms excludes our liability for fraud, or
                    for anything else that cannot be limited by law.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="3">
        <li>
          <p>Suspension or termination</p>
          <ol>
            <li>
              <p>
                Ending your access. We may immediately suspend or terminate
                access to the tool if you use it other than as permitted by us,
                or if we consider that your use of the product could cause a
                detriment to us or others, or for any other reason we require
                (e.g. maintenance, or to make any changes or improvements).
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="4">
        <li>
          <p>Ownership</p>
          <ol>
            <li>
              <p>
                Intellectual property rights. We and our licensors own the tool.
                You have a limited, non-transferable, non-sublicensable licence
                to use it in accordance with these terms only. (Our licensors
                may include an entity you work for).
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="5">
        <li>
          <p>General</p>
          <ol>
            <li>
              <p>Disputes.</p>
              <ol>
                <li>
                  <p>
                    If a dispute arises, you and we will attempt to resolve it
                    by discussion, negotiation and mediation before commencing
                    legal proceedings.
                  </p>
                </li>
                <li>
                  <p>
                    These terms and any dispute arising from them, whether
                    contractual or non-contractual, will be governed by English
                    law, and be subject to the exclusive jurisdiction of English
                    courts.
                  </p>
                </li>
                <li>
                  <p>
                    Any claims must be brought no later than 2 years after the
                    date the claimant should have been aware of the potential
                    claim and, in any event, no later than 4 years after any
                    alleged breach.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Matters beyond reasonable control.We will not be liable to you
                if we fail to meet our obligations due to matters beyond our
                reasonable control.
              </p>
            </li>
            <li>
              <p>
                Rights of third parties. A person who is not a party to these
                terms has no rights to enforce them.
              </p>
            </li>
            <li>
              <p>
                Entire agreement. These terms form the entire agreement between
                you and us in relation to the tool. These terms replace any
                earlier agreements, representations or discussions.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="6">
        <li>
          <p>Other</p>
          <ol>
            <li>
              <p>
                Your personal data. All information, rights and obligations
                concerning personal data in relation to the tool is contained in
                its privacy statement.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <ol start="7">
        <li>
          <p>Definitions</p>
          <ol>
            <li>
              <p>
                The following words and expressions in these terms have the
                following meanings:
              </p>
              <ol>
                <li>
                  <p>
                    content - the information, data, material and any other
                    content on the tool, other than the outputs
                  </p>
                </li>
                <li>
                  <p>
                    outputs - any report, calculation, result or other output
                    produced by the functionality of the tool
                  </p>
                </li>
                <li>
                  <p>
                    these terms - means the agreement formed by this "terms of
                    use" document
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default Terms;
