import React from "react";

const PageNotFound = () => {
  return (
    <div className="ap-container">
      <h2>Page not found</h2>
      <p>This page does not exist.</p>
    </div>
  );
}

export default PageNotFound;
