import React from "react";
import UseAuth from "./hook/UseAuth";
import { Outlet } from "react-router-dom";
import Unauthorized from "./unauthorized";

export default function RequiredAuth({ allowRoles }) {
  const auth = UseAuth();

  return allowRoles.includes(auth.roleId) ? <Outlet /> : <Unauthorized />;
}
