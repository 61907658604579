import axios from "axios";
import secureLocalStorage from "react-secure-storage";

export const getrequest = async (url, data) => {
  let configData = {
    headers: {
      Authorization: "Bearer " + secureLocalStorage.getItem("TOKEN"),
    },
  };
  if (data) {
    if (data.params) configData.params = data.params;
    if (data.responseType) configData.responseType = data.responseType;
  }

  let res = await axios
    .get(url, configData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status == 401) {
        // if(url=="https://questionnairetoolstageapp.azurewebsites.net/api/Survey/GetSurvey?surveyId=survey_4b215983-19fa-4172-96f8-043da86fb5df-637998572834399023&surveyUserEmail=tamilselvi.k@pwc.com"){
        // performOpenamLogin();
        //}

        let tokendetails = {
          accessToken: secureLocalStorage.getItem("TOKEN"),
          refreshToken: secureLocalStorage.getItem("REFRESHTOKEN"),
        };
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/api/Auth/RefreshJwtAccessToken`,
            tokendetails
          )
          .then((response) => {
            if (response) {
              // Cookies.set('TOKEN', response.data.token,{
              //   sameSite: 'strict',
              //   secure: true
              // });
              // Cookies.set('REFRESHTOKEN', response.data.refreshToken,{
              //   sameSite: 'strict',
              //   secure: true
              // });
              secureLocalStorage.setItem("TOKEN", response.data.token);
              secureLocalStorage.setItem(
                "REFRESHTOKEN",
                response.data.refreshToken
              );

              getrequest(url, data);
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              axios
                .get(
                  `${process.env.REACT_APP_API_BASE_URL}/api/Auth/GenerateAccessToken`,
                  {
                    headers: {
                      Authorization:
                        "Bearer " +
                        JSON.parse(secureLocalStorage.getItem("OPENAMSESSION"))
                          .access_token,
                    },
                  }
                )
                .then((response) => {
                  if (response) {
                    // Cookies.set('TOKEN', response.data.token,{
                    //   sameSite: 'strict',
                    //   secure: true
                    // });
                    //  Cookies.set('REFRESHTOKEN', response.data.refreshToken,{
                    //   sameSite: 'strict',
                    //   secure: true
                    // });
                    secureLocalStorage.setItem("TOKEN", response.data.token);
                    secureLocalStorage.setItem(
                      "REFRESHTOKEN",
                      response.data.refreshToken
                    );
                  }
                  getrequest(url, data);
                });
            }
          });
      }
      return err;
    });
  return res;
};
export const postrequest = async (url, data) => {
  let headers = {};
  let formdataURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/AddSurvey`;
  if (url == formdataURL) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + secureLocalStorage.getItem("TOKEN"),
    };
  } else {
    headers = {
      Authorization: "Bearer " + secureLocalStorage.getItem("TOKEN"),
    };
  }
  let res = await axios
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return res;
};
