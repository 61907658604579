import { useState, useEffect } from "react";
import { Panel, Loading } from "@appkit4/react-components";
import HomepageOne from "../assets/images/HomepageOne.png";
import HomepageThree from "../assets/images/HomepageThree.png";
import HomepageFour from "../assets/images/HomepageFour.png";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <div className="row">
        <h2 className="mb-md-3">What is Questionnaire Cloud?</h2>
        <div className="col-4">
          <Panel className="home-panel">
            <img src={HomepageFour} alt="panel one" />
            <div className="padding">
              <p className="ap-panel-content">
                The Questionnaire Cloud is a Tax product that allows PwC users
                to create their own questionnaires and forms to send via email
                to other internal PwC users and external clients.
              </p>
            </div>
          </Panel>
        </div>

        <div className="col-4">
          <Panel className="home-panel">
            <img src={HomepageOne} alt="panel two" />
            <div className="padding">
              <p className="ap-panel-content">
                Team spaces enables users to add other team members with
                different responsibilities, Admin, Content creator, and Data
                extractor. Within the team you are able to manage your
                questionnaires.
              </p>
            </div>
          </Panel>
        </div>

        <div className="col-4">
          <Panel className="home-panel">
            <img src={HomepageThree} alt="panel three" />
            <div className="padding">
              <p className="ap-panel-content">
                Once recipients have completed and submitted the questionnaires
                sent to them, the responses are stored in a Cosmos database
                within the tool which users can extract and download to their
                local machines.
              </p>
            </div>
          </Panel>
        </div>
      </div>
      <div className="row mt-md-3">
        <p>
          For more information, please visit the{" "}
          <a
            className="ap-link"
            href="https://docs.google.com/presentation/d/1gOmD4PNoq99OypoA3qvRCj6T2Ioo4D7P_G41ABRa5bg/edit#slide=id.g132dc11d99a_0_114"
            target="_blank"
            rel="noopener noreferrer"
          >
            Questionnaire Cloud User Guide
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
