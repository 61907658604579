import * as React from "react";
import { useState, useEffect } from "react";
import { getrequest, postrequest } from "../../services/global.service";
import Cookies from "js-cookie";
import {
  Button,
  toaster,
  Notification,
  Badge,
  Modal,
  Loading,
  Input,
  Select,
  Table,
  Column,
  Panel,
} from "@appkit4/react-components";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const AddUploader = () => {
  let navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isvalid, setIsvalid] = useState(true);
  const [roles, setRoles] = useState([]);

  const [formValues, setFormValues] = useState({
    teamName: JSON.parse(Cookies.get("seletedTeam")).name,
    guid: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const userDetailsOpenAM = JSON.parse(Cookies.get("OPENAMUSERDETAIL"));
  useEffect(() => {
    setIsLoading(true);
    setAllUsers();
    getUserRoles();
  }, []);

  const setAllUsers = () => {
    getrequest(
      `${baseURL}/api/UserManagement/GetUserList?teamName=` +
        JSON.parse(Cookies.get("seletedTeam")).name
    )
      .then((response) => {
        setRows(JSON.parse(response.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  }

  const getUserRoles = async () => {
    setIsLoading(true);
    await getrequest(`${baseURL}/api/UserManagement/GetUserRoles`)
      .then((response) => {
        setRoles(JSON.parse(response.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };
  
  const deleteUser = () => {
    setIsLoading(true);
    postrequest(
      `${baseURL}/api/UserManagement/DeleteUser?teamName=` +
        JSON.parse(Cookies.get("seletedTeam")).name,
      selectedUser
    )
      .then((response) => {
        setOpen(false);
        setAllUsers();
        displayAlert(response.data.message);
      })
      .catch(() => {
        navigate("/service-problem");
      });
  }

  const handleInputChange = (value, e) => {
    const { name } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const searchCall = (e) => {
    setFormValues({
      ...formValues,
      firstName: "",
      guid: "",
      lastName: "",
    });
    if (
      formValues.email.substring(formValues.email.lastIndexOf("@") + 1) !=
      "pwc.com"
    ) {
      setIsvalid(false);
      return true;
    } else {
      setIsLoading(true);
      let searchURL = `${baseURL}/api/UserManagement/LDAPSearch?email=`;
      getrequest(searchURL + e.target.value)
        .then((response) => {
          setIsLoading(false);
          if (response.data.data) {
            let userDetailResult = JSON.parse(response.data.data);
            setFormValues({
              ...formValues,
              firstName: userDetailResult.firstName,
              guid: userDetailResult.id,
              lastName: userDetailResult.lastName,
            });
          } else {
            setIsvalid(false);
            return true;
          }
          // setOpen(true);
          // setAlertContent(response.data.message);
        })
        .catch((error) => {
          navigate("/service-problem");
        });
    }
  };

  const handleSubmit = () => {
    setIsvalid(true);
    if (
      formValues.email.substring(formValues.email.lastIndexOf("@") + 1) !==
      "pwc.com"
    ) {
      setIsvalid(false);
      return true;
    }
    setIsLoading(true);
    const payloadData = {
      id: "",
      guid: formValues.guid,
      userDetails: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        roleId: formValues.role,
      },
    };
    const URL =
      `${baseURL}/api/UserManagement/AddUser?teamName=` + formValues.teamName;

    postrequest(URL, payloadData)
      .then((response) => {
        setAllUsers();
        displayAlert(response.data.message);
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };

  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 5000 }
    );
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Button
        onClick={() => {
          navigate("/surveylist");
        }}
        kind="text"
        className="back-button"
      >
        Back
      </Button>
      <Panel title="Uploaders" className="mb-md-3">
        <p>
          Admin can add up to 5 other admins into a team. Please connect with
          the relevant team to add more admins.
        </p>
        <div className="row mb-md-3">
          <Input
            required
            type="text"
            name="teamName"
            id="teamName"
            title="Team Name"
            value={formValues.teamName}
            onChange={handleInputChange}
            disabled
            className="col-3"
          />
          <Input
            required
            name="email"
            type="email"
            id="email"
            title="Email"
            value={formValues.email}
            onChange={handleInputChange}
            onBlur={searchCall}
            error={!isvalid}
            errorNode={
              !isvalid && (
                <div
                  aria-live="polite"
                  className="ap-field-email-validation-error"
                >
                  Please enter a valid PWC email
                </div>
              )
            }
            className="col-3"
          />
          <Input
            required
            type="text"
            name="guid"
            id="guid"
            title="GUID"
            value={formValues.guid}
            onChange={handleInputChange}
            disabled
            className="col-3"
          />
        </div>
        <div className="row mb-md-5">
          <Input
            required
            type="text"
            name="firstName"
            id="FName"
            title="First Name"
            value={formValues.firstName}
            onChange={handleInputChange}
            disabled
            className="col-3"
          />
          <Input
            type="text"
            name="lastName"
            id="LName"
            title="Last Name"
            value={formValues.lastName}
            onChange={handleInputChange}
            disabled
            className="col-3"
          />

          <Select
            required
            className="col-3"
            id="demo-simple-select"
            name="role"
            placeholder="Role"
            value={formValues.role}
            data={roles.map((role) => role.roleName)}
            onSelect={(vals) =>
              setFormValues((prevState) => ({
                ...prevState,
                role: vals,
              }))
            }
          />

          <Button
            kind="secondary"
            icon="icon-plus-fill"
            type="submit"
            onClick={handleSubmit}
            disabled={
              !formValues.email || !formValues.firstName || !formValues.lastName
            }
            className="col-3"
          >
            Add
          </Button>
        </div>
      </Panel>

      <Table originalData={rows} hasTitle striped>
        <Column
          field="guid"
          renderCell={(user) => (
            <p>
              {user.userDetails.firstName} {user.userDetails.lastName}
            </p>
          )}
        >
          Name
        </Column>
        <Column
          field="guid"
          renderCell={(user) => <p>{user.userDetails.roleId}</p>}
        >
          Role
        </Column>

        <Column
          field="guid"
          renderCell={(user) =>
            user.guid !== userDetailsOpenAM.guid && (
              <Button
                kind="primary"
                icon="icon-delete-outline"
                onClick={() => {
                  setSelectedUser(user);
                  setOpen(true);
                }}
              >
                Delete
              </Button>
            )
          }
        >
          {" "}
          Actions
        </Column>
      </Table>

      <Modal
        visible={open}
        title="User being deleted"
        onCancel={() => {
          setSelectedUser("");
          setOpen(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={<Badge type="warning" value="Warning"></Badge>}
        footer={
          <Button onClick={deleteUser} kind="negative">
            Delete
          </Button>
        }
      >
        <p>Are you sure you want to remove the user from team?</p>
      </Modal>
    </div>
  );
}

export default AddUploader;
