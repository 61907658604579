import React from "react";
import Cookies from "js-cookie";

const ServiceProblem = () => {
  return (
    <div className="ap-container">
      <h2>There is a problem with the service.</h2>
      {Cookies.get("OPENAMUSERDETAIL") &&
      JSON.parse(Cookies.get("OPENAMUSERDETAIL"))?.mail.includes("@pwc.com") ? (
        <p>
          If this problem still persists, please submit a support ticket to
          UK-IT-QUESTIONNAIRE-CLOUD-L2 SUPPORT group in{" "}
          <a
            href="https://wwwpwcnetwork.pwc.myshn.net/pwc?shn-direct"
            target="_blank"
            rel="noopener noreferrer"
            className="ap-link"
          >
            ServiceNow
          </a>
          .
        </p>
      ) : (
        <p>
          If this problem still persists, please reach out to your client team
        </p>
      )}
    </div>
  );
}

export default ServiceProblem;
