import React from "react";
import Cookies from "js-cookie";
import { Panel, Button } from "@appkit4/react-components";
import { useLocation } from "react-router-dom";
import surveypage from "../../assets/images/homepage.png";
import { postrequest } from "../../services/global.service";
var logOutSurveyUrl = `${process.env.REACT_APP_API_BASE_URL}/api/UserManagement/LogOut?userEmail=`;

const ThankYouSurvey = () => {
  const location = useLocation();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  const logOut = () => {
    if (Cookies.get("OPENAMUSERDETAIL")) {
      postrequest(
        logOutSurveyUrl + JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail
      ).then((response) => {
        if (response.data.result) {
          Cookies.remove("OPENAMSESSION");
          Cookies.remove("userdetails");
          Cookies.remove("path");
          Cookies.remove("TOKEN");
          Cookies.remove("REFRESHTOKEN");
          Cookies.remove("OPENAMUSERDETAIL");
          window.location.href =
            "https://login-stg.pwc.com:443/openam/UI/Logout";
        }
      });
    } else {
      window.location.href = "https://login-stg.pwc.com:443/openam/UI/Logout";
    }
  }

  return (
    <div className="ap-container">
      <Panel title="Thank you">
        <div className="row">
          <div className="col-8">
            <p>Thank you for submitting your survey.</p>

            <p>You may now logout</p>
            <Button kind="primary" onClick={logOut}>
              Logout
            </Button>
          </div>

          <div className="col-4">
            <img src={surveypage} alt="survey page" />
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default ThankYouSurvey;
