import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Button,
  toaster,
  Input,
  Notification,
  Badge,
  Modal,
  TextArea,
  Loading,
  Panel,
} from "@appkit4/react-components";
import { Table, Column } from "@appkit4/react-components/table";
import { getrequest, postrequest } from "../../services/global.service";
import { useNavigate, useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const Team = () => {
  let navigate = useNavigate();

  const location = useLocation();
  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });
  const [teamsList, setTeamList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [addTeamValues, setAddTeamValues] = useState({
    teamName: "",
  });

  const [deleteTeamValues, setDeleteTeamValues] = useState({
    teamName: "",
    removeReason: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getUserTeamDetails();
  }, []);

  const getUserTeamDetails = async () => {
    Cookies.set("seletedTeam", "", {
      sameSite: "strict",
      secure: true,
    });
    let teamDetailsURL = `${baseURL}/api/Team/GetTeamList?emailId=`;
    await getrequest(
      teamDetailsURL + JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail
    )
      .then((response) => {
        if (response.data.data) setTeamList(JSON.parse(response.data.data));
      })
      .catch((error) => {
        navigate("/service-problem");
      });
    setIsLoading(false);
  };

  const handleClick = () => {
    navigate("/surveylist");
  };

  const handleAddTeamChange = (value, e) => {
    const { name } = e.target;
    setAddTeamValues({
      ...addTeamValues,
      [name]: value,
    });
  };

  const addTeamSubmit = () => {
    setIsLoading(true);
    let payloadData = {
      id: "",
      name: addTeamValues.teamName,
      adminCount: 0,
      contactEmail: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
      reason: "",
      user: {
        id: "",
        guid: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).guid,
        userDetails: {
          firstName: JSON.parse(Cookies.get("userdetails")).firstName,
          lastName: JSON.parse(Cookies.get("userdetails")).lastName,
          email: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
          roleId: JSON.parse(Cookies.get("userdetails")).roleId,
        },
      },
      userEmailList: [],
    };

    let URL = `${baseURL}/api/Team/AddTeam`;
    postrequest(URL, payloadData)
      .then((response) => {
        if (response.data.message === "Team added successfully") {
          Cookies.set("seletedTeam", addTeamValues.teamName, {
            sameSite: "strict",
            secure: true,
          });
          getUserTeamDetails();
          displayAlert(response.data.message);
          setAddTeamValues({
            teamName: "",
            teamContactEmail: "",
          });
        } else {
          setIsLoading(false);
          displayAlert("Something went wrong. Please try again");
        }
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };

  const displayAlert = (text) => {
    return toaster.notify(
      <Notification title="Alert" message={text} status="" closeable />,
      { position: "topCenter", duration: 5000 }
    );
  };

  const deleteTeamSubmit = () => {
    let URL =
      `${baseURL}/api/Team/RemoveTeam?teamName=` +
      deleteTeamValues.teamName +
      `&reason=` +
      deleteTeamValues.removeReason;
    postrequest(URL)
      .then((response) => {
        setDeleteModal(false);
        setDeleteTeamValues((prevState) => ({
          ...prevState,
          teamName: "",
          removeReason: "",
        }));
        getUserTeamDetails();
        displayAlert("Team removed successfully");
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      <Panel title="Teams">
        <div className="row">
          <div className="col-6">
            <Input
              required
              type="text"
              name="teamName"
              id="teamName"
              title="Team Name"
              value={addTeamValues.teamName}
              onChange={handleAddTeamChange}
              className="mb-md-3"
            />
          </div>
          <div className="col-3">
            <Button
              onClick={addTeamSubmit}
              disabled={!addTeamValues.teamName}
              kind="secondary"
            >
              Add Team
            </Button>
          </div>
        </div>
      </Panel>

      <Table originalData={teamsList} hasTitle striped className="mt-md-3">
        <Column
          field="name"
          renderCell={(row) => (
            <Button
              kind="text"
              disabled={row.isActive !== true && row.isUserActive !== true}
              onClick={() => {
                Cookies.set("seletedTeam", JSON.stringify(row), {
                  sameSite: "strict",
                  secure: true,
                });
                handleClick();
              }}
            >
              {row.name}
            </Button>
          )}
        >
          {" "}
          Name
        </Column>
        <Column
          field="name"
          renderCell={(row) => (
            <Button
              kind="primary"
              icon="icon-delete-outline"
              disabled={
                row.isActive !== true ||
                row.isUserActive !== true ||
                row.roleId !== "Admin"
              }
              onClick={() => {
                setDeleteModal(true);
                setDeleteTeamValues((prevState) => ({
                  ...prevState,
                  teamName: row.name,
                }));
              }}
            >
              Delete
            </Button>
          )}
        ></Column>
      </Table>

      <Modal
        visible={deleteModal}
        title={`${deleteTeamValues.teamName} being deleted`}
        onCancel={() => {
          setDeleteTeamValues((prevState) => ({
            ...prevState,
            teamName: "",
            removeReason: "",
          }));
          setDeleteModal(false);
        }}
        modalStyle={{ width: "33.75rem" }}
        footerStyle={{
          paddingTop: "8px",
          marginTop: "-8px",
          minHeight: "64px",
        }}
        header={<Badge type="warning" value="Warning"></Badge>}
        footer={
          <Button
            onClick={deleteTeamSubmit}
            kind="negative"
            disabled={
              !deleteTeamValues.teamName || !deleteTeamValues.removeReason
            }
          >
            Delete
          </Button>
        }
        bodyStyle={{ minHeight: "92px" }}
      >
        <p>
          Existing team data will remain in the database, but users will not be
          able to submit new data.
        </p>
        <TextArea
          title="Reason for removing"
          maxLength={420}
          value={deleteTeamValues.removeReason}
          onChange={(value) =>
            setDeleteTeamValues((prevState) => ({
              ...prevState,
              removeReason: value,
            }))
          }
        ></TextArea>
      </Modal>
    </div>
  );
}

export default Team;
