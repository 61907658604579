import React from "react";
import { useLocation, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Table, Column } from "@appkit4/react-components";

const CookiesStatement = () => {
  const location = useLocation();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  const sectionA = [
    {
      id: 1,
      name: "userdetails",
      party: "First",
      duration: "Available till the user logout(session cookies)",
      description:
        "To store the user details( like email for reference)of the logged in user",
      sharedWith: "PwC",
    },
    {
      id: 2,
      name: "path",
      party: "First",
      duration: "Available till the user logout(session cookies)",
      description: "To store the path of the application",
      sharedWith: "PwC",
    },
    {
      id: 3,
      name: "OPENAMUSERDETAIL",
      party: "First",
      duration: "Available till the user logout(session cookies)",
      description: "To store the user details received from openam",
      sharedWith: "PwC",
    },
    {
      id: 4,
      name: "OPENAMSESSION",
      party: "First",
      duration: "Available till the user logout(session cookies)",
      description: "To check the openam session validity ",
      sharedWith: "PwC",
    },
  ];

  const sectionB = [
    {
      id: 1,
      name: "_ga",
      party: "third",
      duration: "399days",
      description: "Used to distinguish users",
      sharedWith: "",
    },
    {
      id: 2,
      name: "__cuid",
      party: "third",
      duration: "394days",
      description:
        "This cookie is written with a different visitors unique ID. This cookie is used to determine unique visitors to the site and it is updated with each page view. Additionally, this cookie is provided with a unique ID that the application uses to ensure both the validity and accessibility of the cookie as an extra security measure.",
      sharedWith: "",
    },
  ];

  const sectionC = [
    {
      id: 1,
      name: "amp_fef1e8",
      party: "third",
      duration: "359days",
      description:
        "Supports Amplitude product analytics. The main purpose of this cookie is: Targeting/Advertising",
      sharedWith: "",
    },
  ];

  return (
    <div className="ap-container">
      <h2>Cookies Statement</h2>
      <h3>Introduction</h3>
      <p>
        The application, Questionnaire tool aims to create a survey tool. The
        authentication is based on PwC’s Authentication tool OpenAM for both
        internal and external users. There will be three user roles like Admin,
        Content Create & Content Extractor.
      </p>

      <p>
        We've designed this information page to provide our application’s
        visitors with accessible, transparent information about the cookies we
        use.
      </p>

      <p>
        This information is relevant for visitors of the Questionnaire tool. For
        more information about how we process your personal data collected
        through the application, Questionnaire tool , please visit our{" "}
        <Link
          to="/privacy-statement"
          target="_blank"
          rel="noopener noreferrer"
          className="ap-link"
        >
          Privacy Statement
        </Link>
        .
      </p>

      <h3>Changes to this Cookie Policy</h3>
      <p>
        We recognise that transparency is an ongoing responsibility so we will
        keep this Cookie Policy under regular review.
      </p>

      <h3>What are Cookies?</h3>
      <p>
        Cookies are small text files that are placed on your computer by the
        Questionnaire Tool when you visit. They are used in order to make the
        Questionnaire Tool work, or work more efficiently, as well as to provide
        statistical information to site owners. Even though some cookies enable
        the display of relevant advertising when you move from site to site, no
        advertisements, be it regular or targeted, are shown in the
        Questionnaire Tool application.{" "}
      </p>

      <h3>Our Cookies your Choice</h3>

      <p>We use two types of cookies on our site: </p>

      <h5>Necessary Cookies</h5>
      <p>
        These cookies are necessary for our Questionnaire Tool to operate. Our
        Questionnaire Tool cannot function without these cookies so they are
        always set on.{" "}
      </p>

      <h5>Marketing Cookies </h5>
      <p>
        The tables below provide details about the actual cookies we use of each
        type. We’ve included information on the duration of each cookie, its
        purpose and if its use involves the transfer of information to any of
        our third party business partners.
      </p>
      <p>These partners include openAM.</p>

      <h3>Section A: Strictly Necessary Cookies</h3>

      <Table originalData={sectionA} hasTitle striped>
        <Column field="name" sortKey="name">
          Cookie Name
        </Column>
        <Column field="party" sortKey="party">
          First or third party Cookie?
        </Column>
        <Column field="duration" sortKey="duration">
          Cookie Duration
        </Column>
        <Column field="description" sortKey="description">
          Cookie Description
        </Column>
        <Column field="sharedWith" sortKey="sharedWith">
          Information shared with
        </Column>
      </Table>

      <h3>Section B: Analytics Cookies</h3>

      <Table originalData={sectionB} hasTitle striped>
        <Column field="name" sortKey="name">
          Cookie Name
        </Column>
        <Column field="party" sortKey="party">
          First or third party Cookie?
        </Column>
        <Column field="duration" sortKey="duration">
          Cookie Duration
        </Column>
        <Column field="description" sortKey="description">
          Cookie Description
        </Column>
        <Column field="sharedWith" sortKey="sharedWith">
          Information shared with
        </Column>
      </Table>

      <h3>Section C: Marketing Cookies</h3>

      <Table originalData={sectionC} hasTitle striped>
        <Column field="name" sortKey="name">
          Cookie Name
        </Column>
        <Column field="party" sortKey="party">
          First or third party Cookie?
        </Column>
        <Column field="duration" sortKey="duration">
          Cookie Duration
        </Column>
        <Column field="description" sortKey="description">
          Cookie Description
        </Column>
        <Column field="sharedWith" sortKey="sharedWith">
          Information shared with
        </Column>
      </Table>

      <h3>Managing Cookies on your device</h3>
      <p>
        We use cookies to personalize content and to provide you with an
        improved user experience. You can control and manage cookies using your
        browser (see below). Please note that removing or blocking cookies can
        impact your user experience and some functionality may no longer be
        available.
      </p>
      <p>
        Necessary cookies are used to store the user details for reference
        purposes.So if those are deleted the functionality of the portal will
        get impact.The marketing and analytics cookies are used by form io and
        we are not using those in our portal functionalities.
      </p>

      <h3>Using your browser to control Cookies</h3>
      <p>
        Most browsers allow you to view, manage, delete and block cookies for a
        website. Be aware that if you delete all cookies then any preferences
        you have set will be lost, including the ability to opt-out from cookies
        as this function itself requires placement of an opt out cookie on your
        device. Guidance on how to control cookies for common browsers is linked
        below.
      </p>

      <ul>
        <li>
          <a
            className="ap-link"
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Chrome
          </a>
        </li>
        <li>
          <a
            className="ap-link"
            href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            className="ap-link"
            href="https://support.apple.com/en-us/HT201265"
            target="_blank"
            rel="noopener noreferrer"
          >
            MacOS Safari
          </a>
        </li>
        <li>
          <a
            className="ap-link"
            href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Internet Explorer
          </a>
        </li>
      </ul>

      <p>
        For information on additional browsers and device types please see{" "}
        <a
          className="ap-link"
          href="http://www.aboutcookies.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.aboutcookies.org/
        </a>{" "}
        or{" "}
        <a
          className="ap-link"
          href="http://www.cookiecentral.com/faq/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.cookiecentral.com/faq/
        </a>
        .
      </p>
    </div>
  );
};

export default CookiesStatement;
