import React from "react";

const Unauthorized = () => {
  return (
    <div className="ap-container">
      <h2>Something went wrong</h2>
      <p>
        You do not have permission to view this page or perform this action.
      </p>
    </div>
  );
}

export default Unauthorized;
