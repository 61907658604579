import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import secureLocalStorage from "react-secure-storage";
import { CheckAuthentication } from "./authentication.service";
export const CheckAuthorization = async () => {
  var openam = {
    authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
    user_profile_url: process.env.REACT_APP_USER_PROFILE_URL,
    app_url: process.env.REACT_APP_APP_URL,
    //app_url: 'http://localhost:3000',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    logoutUrl: process.env.REACT_APP_LOGOUTURL,
    token_info: process.env.REACT_APP_TOKEN_INFO,
  };
  var hosturl =
    openam.user_profile_url +
    "?access_token=" +
    JSON.parse(secureLocalStorage.getItem("OPENAMSESSION")).access_token;
  const headersObj = new Headers();
  headersObj.set("Content-Type", "application/x-www-form-urlencoded");
  headersObj.append(
    "Authorization",
    "Bearer " +
      JSON.parse(secureLocalStorage.getItem("OPENAMSESSION")).access_token
  );
  //openam api call
  var response = fetch(hosturl, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: headersObj,
  }).then((response) => {
    if (!response.ok) {
      CheckAuthentication();
    }
  });
  let token = JSON.parse(secureLocalStorage.getItem("OPENAMSESSION")).id_token;
  const user = jwt_decode(token);

  const firstName = user.given_name
    ? user.given_name
    : this.getFirstName(user.upn);
  const lastName = user.family_name
    ? user.family_name
    : this.getLastName(user.upn);
  let result = {
    given_name: firstName,
    family_name: lastName,
    unique_name: user.name ? user.name : firstName + " " + lastName,
    role: user.role,
    guid: user.sub || user.uid,
    mail: user.preferredMail,
    claims: null,
  };
  await Cookies.set("OPENAMUSERDETAIL", JSON.stringify(result), {
    sameSite: "strict",
    secure: true,
  });

  return result;
};
